<template>
  <!-- 登录界面 -->
  <div class="index">
    <!-- logo 以及栏目导航 -->
    <div class="row">
      <router-link to="/">
        <img
          src="../imger/WBWlogo02.png"
          class="icon2"
          title="问标网"
          alt="问标网"
        />
      </router-link>
      <div class="trademark">
        <a href="/sblb" id="W01">商标分类</a><span>|</span>
        <a href="/zcsb" id="W01">注册商标</a><span>|</span>
        <a href="/server" id="W01">服务中心</a><span>|</span>
        <a href="/news" id="W01">新闻资讯</a><span>|</span>
        <a href="/about" id="W01">关于我们</a><span>|</span>
        <a href="/contact" id="W01">联系我们</a>
      </div>
    </div>

    <!-- 登录输入 -->
    <div class="loginBox">
      <div class="loginBoxLeft">
        <img
          class="WBWlogo"
          src="../imger/WBWlogo01.png"
          title="问标网"
          alt="问标网"
        />

        <div class="logoBox" v-for="(item, index) in logoboxdata" :key="index">
          <img class="icon" :src="item.icon" :alt="item.word01" />
          <div class="wear">
            <p class="word01">{{ item.word01 }}</p>
            <p>{{ item.word02 }}</p>
          </div>
        </div>
      </div>
      <div class="loginBoxRight" v-show="loginMode">
        <strong class="loginInputtitle">快捷登录</strong>
        <input
          class="phone"
          type="text"
          placeholder="请输入手机号"
          v-model="phone"
        />
        <div class="passwordbox">
          <input
            class="password"
            type="text"
            placeholder="请输入验证码"
            v-model="code"
          />
          <el-button class="obtain" @click="obtaincode" :disabled="disabled">{{
            codetext
          }}</el-button>
        </div>
        <button class="login" @click="loging">
          <span class="loginWord">登录</span>
        </button>
        <p class="scanCode" @click="loginFonction(false)">扫码登陆</p>
      </div>
      <div class="loginBoxRight" v-show="!loginMode">
        <p class="welcome">欢迎登陆问标网</p>
        <img class="vx" alt="" />
        <p id="word01">
          <img
            src="../imger/saoma.png"
            alt="欢迎登录问标网"
          />微信扫一扫登录问标网账号
        </p>
        <p class="scanCode" @click="loginFonction(true)">账号登陆</p>
      </div>
    </div>
    <div class="foot">
      <p>www.iwenbiao.com 版权所有©广州问权知识产权服务有限公司</p>
      <p>
        地址：广东省广州市海珠区（琶洲）中洲中心南塔B座15楼
        ICP备案号：粤ICP备20064697号
      </p>
    </div>
  </div>
</template>

<script>
import icon01 from "../imger/login/icon01.png";
import icon02 from "../imger/login/icon02.png";
import icon03 from "../imger/login/icon03.png";
import icon04 from "../imger/login/icon04.png";

import { sengOut, receive, newkeyCode } from "../API/login";
import { keyCode, newIP } from "../API/index";
import { userInfo } from "../API/user";

export default {
  data() {
    return {
      logoboxdata: [
        {
          icon: icon01,
          word01: "全量商标",
          word02: "超5000万+商标全量大数据",
        },
        {
          icon: icon02,
          word01: "即时更新",
          word02: "采用人工智能技术实时更新",
        },
        {
          icon: icon03,
          word01: "免费查询",
          word02: "商标注册信息免费查询工具",
        },
        {
          icon: icon04,
          word01: "贴心服务",
          word02: "查询/注册/认领—站式服务",
        },
      ],
      codetext: "获取验证码",
      phone: "",
      code: "",
      disabled: false, //决定获取验证码按钮是否禁用
      totalTime: 60,
      ip: "",
      loginMode: false,
      openNum: 0, //websocket会话次数存储
      userdata: "",
      jiesu: null, //定时器
      timenumber: 1,
      ws:null
    };
  },

  async created() {
    await this.keyCode();
    let _this = this;
    // 建立连接
    _this.ws = new WebSocket(
      "wss://api.iwenbiao.com/user/api/v1/web/user/websocket_login"
    );

    // 发送消息
    _this.ws.onopen = function () {
      let newRoom_id = "";
      if (sessionStorage.getItem("room_id") !== null) {
        newRoom_id = sessionStorage.getItem("room_id");
      }
      let newsocket = {
        ip: _this.ip,
        key: sessionStorage.getItem("key"),
        platform: "问标",
        room_id: newRoom_id,
      };
      _this.openNum = 0; //onmessage的会话次数清零
      _this.ws.send(JSON.stringify(newsocket));
      // 定时重发
      _this.jiesu = setInterval(() => {
        if (_this.timenumber == 0) {
          let Room_id = "";
          if (sessionStorage.getItem("room_id") !== null) {
            Room_id = sessionStorage.getItem("room_id");
          }
          let socket = {
            ip: _this.ip,
            key: sessionStorage.getItem("key"),
            platform: "问标",
            room_id: Room_id,
          };
          _this.openNum = 0; //onmessage的会话次数清零
          _this.ws.send(JSON.stringify(socket));
        }
        if (_this.timenumber == 179) {
          _this.timenumber = 0;
        } else {
          _this.timenumber++;
        }
      }, 1000);
    };

    // 接受消息
    _this.ws.onmessage = function (data) {
      _this.timenumber = 1;
      const image = JSON.parse(data.data);
      // 会话次数存储
      _this.openNum++;
      if (_this.openNum == 1) {
        document
          .querySelector(".vx")
          .setAttribute("src", `data:image/jpeg;base64,${image.image}`);
        sessionStorage.setItem("room_id", image.room_id);
      }
      if (_this.openNum == 2) {
        if (image.status == "ok") {
          _this.timenumber = 1; //清空计时
        }
      }
      if (_this.openNum == 3) {
        _this.userdata = JSON.parse(image);
      }
    };
    // 断开链接时
    _this.ws.onclose = async function () {
      // 连开链接时经行重连
      if (
        _this.userdata.user_id == undefined ||
        _this.userdata.phone == undefined
      ) {
        _this.ws = new WebSocket(
          "wss://api.iwenbiao.com/user/api/v1/web/user/websocket_login"
        );
        _this.timenumber = 1; //重连之后立刻进行发送消息回调函数
      } else {
        _this.openNum = 0; //次数清零
        // 获取userkey
        const reskey = await newkeyCode({
          key: sessionStorage.getItem("key"),
          userid: _this.userdata.user_id,
          phone: _this.userdata.phone,
        });
        if (reskey.msg == "success") {
          await sessionStorage.setItem("userkey", reskey.data);
          // 查看个人信息
          const res = await userInfo({
            key: sessionStorage.getItem("userkey"),
            phone: _this.userdata.phone,
            user_id: _this.userdata.user_id,
          });
          if (res.data.key) {
            await localStorage.setItem(
              "user",
              JSON.stringify(res.data.user_data)
            );
            await sessionStorage.setItem("userkey", res.data.key);
          }
          if (history.length == 2) {
            _this.$router.go(-1);
          } else {
            _this.$router.push({ path: "/" });
          }
        }
      }
    };
  },
  destroyed() {
    let _this = this;
    // 删除roomid
    sessionStorage.removeItem("room_id");
    clearInterval(_this.jiesu);
    console.log("氢气回去",_this.ws);
    _this.ws.close();
  },
  methods: {
    loginFonction(data) {
      this.loginMode = data;
    },
    // 获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == "" || duan == null || duan == undefined) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },
    // 发送验证码
    async obtaincode() {
      if (this.phone) {
        if (
          /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
            this.phone
          )
        ) {
          //登录获取验证码请求
          const res = await sengOut({
            key: sessionStorage.getItem("key"),
            phone: this.phone,
          });
          this.$message({
            message: "发送成功，请注意查看手机短信",
            type: "success",
          });
          if (res.data.key) {
            await sessionStorage.setItem("key", res.data.key);
            //将获取验证码的按钮禁用并在倒计时结束之后重新启用
            this.disabled = true;
            this.codetext = this.totalTime + "s后重新发送";
            let clock = window.setInterval(() => {
              this.totalTime--;
              this.codetext = this.totalTime + "s后重新发送";
              if (this.totalTime < 0) {
                window.clearInterval(clock);
                this.codetext = "获取验证码";
                this.totalTime = 60;
                this.disabled = false;
              }
            }, 1000);
          }
        } else {
          this.$message({
            message: "您输入的手机号有误，请重新输入。",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
      }
    },
    // 校验验证码
    async loging() {
      if (this.phone && this.code) {
        //登录手机号验证码验证请求
        const res = await receive({
          key: sessionStorage.getItem("key"),
          code: this.code,
          phone: this.phone,
          platform: "问标",
        });

        if (res.data.key) {
          await sessionStorage.setItem("key", res.data.key);
          await localStorage.setItem("user", JSON.stringify(res.data.user));
          // this.$router.push({path:'/'})
          this.$message({
            message: "登录成功！",
            type: "success",
          });
          const reskey = await newkeyCode({
            key: sessionStorage.getItem("key"),
            userid: res.data.user.id,
            phone: res.data.user.phone,
          });
          await sessionStorage.setItem("userkey", reskey.data);

          this.$router.go(-1);
        } else {
          this.$message({
            message: "验证码输入错误!",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "登录失败!",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  height: 67.5rem /* 1080px -> 67.5rem */;
  background-image: url("../imger/login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .row {
    position: absolute;
    top: 1.875rem /* 30px -> 1.875rem */;
    width: 100%;
    display: flex;
    justify-content: space-around;
    .icon2 {
      margin-top: 0.6875rem /* 11px -> .6875rem */;
      display: block;
    }
    .trademark {
      margin-top: 1.625rem /* 26px -> 1.625rem */;
      color: #fff;
      display: flex;
      justify-content: space-between;
      width: 25.75rem /* 412px -> 25.75rem */;
      font-size: 0.875rem /* 14px -> .875rem */;
      #W01 {
        color: #fff;
        font-size: 0.875rem /* 14px -> .875rem */;
        text-decoration: none;
      }
    }
  }
  .loginBox {
    position: absolute;
    top: 12.6875rem /* 203px -> 12.6875rem */;
    left: 50%;
    transform: translatex(-50%);
    width: 68.9375rem /* 1103px -> 68.9375rem */;
    height: 39.75rem /* 636px -> 39.75rem */;
    background-image: url("../imger/loginInput.png");
    background-size: 100% 100%;
    display: flex;
    .loginBoxLeft {
      width: 34.125rem /* 546px -> 34.125rem */;
      height: 39.75rem /* 636px -> 39.75rem */;
      .WBWlogo {
        margin-top: 5rem /* 80px -> 5rem */;
        margin-left: 13.5rem /* 216px -> 13.5rem */;
        width: 13.1875rem /* 211px -> 13.1875rem */;
      }
      .logoBox {
        width: 16.5rem /* 264px -> 16.5rem */;
        height: 2.9375rem /* 47px -> 2.9375rem */;
        margin-left: 12.3125rem /* 197px -> 12.3125rem */;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        display: flex;
        justify-content: space-between;
        .icon {
          width: 3rem /* 48px -> 3rem */;
          height: 100%;
        }
        .wear {
          height: 100%;
          font-size: 1rem /* 16px -> 1rem */;
          color: #999999;
          line-height: 1.5625rem /* 25px -> 1.5625rem */;
          .word01 {
            font-size: 1.125rem /* 18px -> 1.125rem */;
            color: #666666;
          }
        }
      }
    }
    .loginBoxRight {
      margin-top: 7.5rem /* 120px -> 7.5rem */;
      margin-left: 1.875rem /* 30px -> 1.875rem */;
      width: 26.25rem /* 420px -> 26.25rem */;
      height: 31.25rem;
      border-top-right-radius: 0.625rem /* 10px -> 0.625rem */;
      border-bottom-right-radius: 0.625rem /* 10px -> 0.625rem */;
      padding-top: 1.5625rem /* 25px -> 1.5625rem */;
      padding-bottom: 1.5625rem /* 25px -> 1.5625rem */;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      // justify-content: space-evenly;
      align-items: center;
      .loginInputtitle {
        font-size: 1.5rem /* 24px -> 1.5rem */;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.0625rem /* 1px -> 0.0625rem */;
      }
      .phone {
        border: none;
        width: 23.3125rem /* 373px -> 23.3125rem */;
        height: 2.75rem /* 44px -> 2.75rem */;
        outline: none;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #e5e5e5;
        padding-left: 1.25rem /* 20px -> 1.25rem */;
        padding-right: 1.25rem /* 20px -> 1.25rem */;
        box-sizing: border-box;
        font-size: 1.25rem /* 20px -> 1.25rem */;
        margin-top: 3.75rem;
      }
      .passwordbox {
        width: 23.3125rem /* 373px -> 23.3125rem */;
        height: 2.75rem /* 44px -> 2.75rem */;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #e5e5e5;
        display: flex;
        margin-top: 1.875rem;
        .password {
          width: 16.875rem /* 270px -> 16.875rem */;
          height: 100%;
          padding-left: 1.25rem /* 20px -> 1.25rem */;
          padding-right: 1.25rem /* 20px -> 1.25rem */;
          box-sizing: border-box;
          outline: none;
          border: none;
          border-right: 0.0625rem /* 1px -> 0.0625rem */ solid #e5e5e5;
          font-size: 1.25rem /* 20px -> 1.25rem */;
        }
        .obtain {
          padding: 0;
          text-align: center;
          flex-grow: 1;
          border: none;
          background-color: #fff;
          font-size: 1rem /* 16px -> 1rem */;
          .obtainWord {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #4d89f1;
          }
        }
      }
      .login {
        width: 23.25rem /* 372px -> 23.25rem */;
        height: 2.75rem /* 44px -> 2.75rem */;
        background: #4d89f1;
        border-radius: 0.3125rem /* 5px -> 0.3125rem */;
        margin-top: 1.875rem;
        border: none;
        outline: none;
        cursor: pointer;
        .loginWord {
          font-size: 1.25rem /* 20px -> 1.25rem */;
          color: #fff;
        }
      }
      .login:hover {
        background: #71a1f5 !important;
      }
      .scanCode {
        position: absolute;
        bottom: 5rem;
        // margin-top: 5rem;
        font-size: 1.25rem;
        color: #333333;
        cursor: pointer;
      }
      // 扫码
      .welcome {
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #333333;
      }
      .vx {
        margin-top: 1.875rem;
        width: 14.375rem;
      }
      #word01 {
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #989898;
        margin-left: 0.625rem /* 10px -> 0.625rem */;
        display: flex;
        align-items: center;
        margin-top: 1.875rem;
      }
      .scanCode:hover {
        color: #4d89f1;
        text-decoration-line: underline;
        text-decoration-color: #4d89f1;
        text-underline-position: under;
      }
    }
  }
  .foot {
    position: absolute;
    bottom: 4.0625rem /* 65px -> 4.0625rem */;
    left: 50%;
    transform: translateX(-50%);
    width: 41.8125rem /* 669px -> 41.8125rem */;
    font-size: 1rem /* 16px -> 1rem */;
    color: #ffffff;
    text-align: center;
  }
}
</style>
